import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import { getBaseUrl } from "./../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import SwiperSlider from "./SwiperSlider";
import Sliderimg from "./Sliderimg";
import Industries from "./Industries";
import CarouselPage from "./CarouselPage";
import CardSlider from "./CardSlider";
import CardSlider2 from "./CardSlider2";
import CardSlider3 from "./Carousel/CarouselT1";




import '../App.css';

const containerStyles = {
  width: "500px",
  height: "280px",
  margin: "0 auto",
};

function Index() {
  const [isVisible, setIsVisible] = useState(false);
  console.log("isVisible------>", isVisible);
  const hide = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(hide, 10000);
    //return () => clearTimeout(timer);

    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
      <>
        <>
          <Navbar />
          <div className="container-fluid" style={{ padding: 0, margin: 0 }}>
            <div className="row" style={{ padding: 0, margin: 0 }}>
              <div className="col-12" style={{ marginTop: 72, padding: 0, margin: 0 }}>
                <div className="main_section"
                  style={{ display: isVisible ? "none" : "block" }}
                  id="First_div"
                >
                  <div>
                    <video
                      src="assets/HomeIimages/Home_Images/hero-sect-video.webm"
                      autoPlay
                      muted
                      className="video_section"
                    ></video>
                  </div>
                  <div className="Text_div_section">
                    <h1>Building Tech Future With Innovation!</h1>
                    <div className="animeted_section">
                      <div>
                        <h1>#1</h1>
                      </div>
                      <div className="text_animeted">
                        <p>
                          <span className="website">Website</span>
                          <br />
                          Development company in 2024
                        </p>
                      </div>
                    </div>
                    <Link to="/contact-us" className="cta">
                      {/* <a className="cta"> */}
                        <span>Drop Your Queries</span>
                        <svg width="13px" height="10px" viewBox="0 0 13 10">
                          <path d="M1,5 L11,5" />
                          <polyline points="8 1 12 5 8 9" />
                        </svg>
                      {/* </a> */}
                    </Link>
                    <h5>Official Partner Of :</h5>
                    <div className="images_partner">
                      <img src="/assets/HomeIimages/Home_Images/ADvocate.png" alt="images" />
                      <img src="/assets/HomeIimages/Home_Images/IQC.png" alt="images" />
                      <img src="/assets/HomeIimages/Home_Images/Pay.png" alt="images" />
                      <img src="/assets/HomeIimages/Home_Images/SURAKARAN.png" alt="images" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper mySwiper"
            id="video_section"
            style={{ display: isVisible ? "block" : "none" }}
          >
            {/* <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className=" col-md-7 First_slide_Text"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <div className="images">
                      <img src="assets/HomeIimages/Home_Images/Group 1000003595 (2).png" />
                    </div>
                    <div className="text_section_slide">
                      <h1 style={{ color: "rgb(171, 97, 236)" }}>Mobile App </h1>
                      <h3>Development Company</h3>
                      <div className="section_ranked">
                        <div>
                          <h1 style={{ color: "rgb(171, 97, 236)" }}>#1</h1>
                        </div>
                        <div className="text_ranked">
                          <p>Ranked as #1 Top</p>
                          <p>App Development Company In Rohini</p>
                          <p>in India &amp; UAE</p>
                        </div>
                      </div>
                      <div className="buttons">
                        <button style={{ background: "rgb(171, 97, 236)" }}>
                          Get Quotes
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-5 first_slide_images"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <img
                      src="assets/HomeIimages/Home_Images/Desktop - 14@0.5x.png"
                      alt=""
                      className="desktop-images"
                    />
                    <img
                      src="assets/HomeIimages/Home_Images/Desktop - 16@0.33x.png"
                      alt=""
                      className="images_mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="col-lg-7 First_slide_Text"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <div className="images">
                      <img src="assets/HomeIimages/Home_Images/Group 1000003595 (1).png" />
                    </div>
                    <div className="text_section_slide">
                      <h1 style={{ color: "#01C8FE" }}>Website</h1>
                      <h3>Development Company</h3>
                      <div className="section_ranked">
                        <div>
                          <h1 style={{ color: "#01C8FE" }}>#1</h1>
                        </div>
                        <div className="text_ranked">
                          <p>Ranked as #1 Top</p>
                          <p>App Development Company In Rohini</p>
                          <p>in India &amp; UAE</p>
                        </div>
                      </div>
                      <div className="buttons">
                        <button style={{ background: "#01C8FE" }}>Get Quotes</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 first_slide_images"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <img
                      src="assets/HomeIimages/Home_Images/Desktop - 15@0.5x.png"
                      alt=""
                      className="desktop-images"
                    />
                    <img
                      src="assets/HomeIimages/Home_Images/Desktop - 17@0.33x.png"
                      alt=""
                      className="images_mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="col-lg-7 First_slide_Text"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <div className="images">
                      <img src="assets/HomeIimages/Home_Images/Desktop - 13.png" />
                    </div>
                    <div className="text_section_slide">
                      <h1 style={{ color: "#f54441" }}>Website</h1>
                      <h3 style={{ color: "rgb(139, 33, 33)" }}>Development Company</h3>
                      <div className="section_ranked">
                        <div>
                          <h1 style={{ color: "#f54441" }}>#1</h1>
                        </div>
                        <div className="text_ranked">
                          <p>Ranked as #1 Top</p>
                          <p>App Development Company In Rohini</p>
                          <p>in India &amp; UAE</p>
                        </div>
                      </div>
                      <div className="buttons">
                        <button style={{ background: "#f54441" }}>Get Quotes</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 first_slide_images"
                    style={{ paddingTop: 60, margin: 0 }}
                  >
                    <img
                      src="assets/HomeIimages/Home_Images/ecommerce-development-image.webp"
                      alt=""
                      className="desktop-images"
                    />
                    <img
                      src="assets/HomeIimages/Home_Images/ecommerce-development-image.webp"
                      alt=""
                      className="images_mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="col-lg-7 First_slide_Text"
                    style={{ paddingTop: 60, margin: 0 }}
                  >
                    <div className="images">
                      <img src="assets/HomeIimages/Home_Images/Desktop - 23.png" />
                    </div>
                    <div className="text_section_slide">
                      <h1 style={{ color: "#fe0166" }}>Website</h1>
                      <h3 style={{ color: "#681234" }}>Development Company</h3>
                      <div className="section_ranked">
                        <div>
                          <h1 style={{ color: "#fe0166" }}>#1</h1>
                        </div>
                        <div className="text_ranked">
                          <p>Ranked as #1 Top</p>
                          <p>App Development Company In Rohini</p>
                          <p>in India &amp; UAE</p>
                        </div>
                      </div>
                      <div className="buttons">
                        <button style={{ background: "#fe0166" }}>Get Quotes</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 first_slide_images"
                    style={{ paddingTop: 60, margin: 0 }}
                  >
                    <img
                      src="assets/HomeIimages/Home_Images/mobile-app-development-image.webp"
                      alt=""
                      className="desktop-images"
                    />
                    <img
                      src="assets/HomeIimages/Home_Images/mobile-app-development-image.webp"
                      alt=""
                      className="images_mobile"
                    />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="col-lg-7 First_slide_Text"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <div className="images">
                      <img src="assets/HomeIimages/Home_Images/Desktop - 24.png" />
                    </div>
                    <div className="text_section_slide">
                      <h1 style={{ color: "#19ddd3" }}>Website</h1>
                      <h3 style={{ color: "#0c4488" }}>Development Company</h3>
                      <div className="section_ranked">
                        <div>
                          <h1 style={{ color: "#19ddd3" }}>#1</h1>
                        </div>
                        <div className="text_ranked">
                          <p>Ranked as #1 Top</p>
                          <p>App Development Company In Rohini</p>
                          <p>in India &amp; UAE</p>
                        </div>
                      </div>
                      <div className="buttons">
                        <button style={{ background: "#19ddd3" }}>Get Quotes</button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-5 first_slide_images"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <img
                      src="assets/HomeIimages/Home_Images/full-stack-development-image.webp"
                      alt=""
                      className="desktop-images"
                    />
                    <img
                      src="assets/HomeIimages/Home_Images/full-stack-development-image.webp"
                      alt=""
                      className="images_mobile"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <Sliderimg />
          </div>

          {/* end slider section coding */}
          {/* start client coding */}
          {/* start section coding */}
          <section className={1}>
            <section className="slide-option">
              <div id="infinite" className="highway-slider">
                <div
                  className="Clients_section"
                  style={{ padding: 0, margin: 0 }}
                >
                  <h1>Our Clients</h1>
                </div>
                <div className="container-fluid highway-barrier">
                  <ul className="highway-lane">
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main1.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main2.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main4.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main5.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main6.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main1.png" alt="prop"/>
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main4.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main2.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main5.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main6.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main7.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main1.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
          {/* <section className={1}>
            <section className="slide-option">
              <div id="infinite" className="highway-slider">
                <div
                  className="col-12 Clients_section"
                  style={{ padding: 0, margin: 0 }}
                >
                  <h1>Our Clients</h1>
                </div>
                <div className="container-fluid highway-barrier">
                  <ul className="highway-lane">
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main1.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main2.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main4.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main5.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main6.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main1.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main4.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main2.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main5.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main6.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main7.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main1.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Clip path group.png" />
                      </span>
                    </li>
                    <li className="highway-car">
                      <span>
                        {" "}
                        <img src="assets/HomeIimages/Home_Images/Main3.svg" />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section> */}
          {/* end section coding */}
          {/* end client coding */}
          {/* start serviecs coding */}
          <section className={2}>
            <div
              className="text_section_services"
              style={{ paddingTop: 70, paddingBottom: 50 }}
              overflow="hidden;"
            >
              <div className="row" style={{ padding: 0, margin: 0 }}>
                <div
                  className="col-12 text_section_choose"
                  style={{ padding: 0, margin: 0 }}
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration={1500}
                >
                  <div className="text_section_services_ind_2">
                    <h2>Our Services</h2>
                    <p className="desktop_text">
                      <strong> Mobile App Development Service in Rohini</strong> is a comprehensive solution for all
                      your needs. From maintenance and repairs to installations and
                      upgrades, we offer a wide range of services to ensure your
                      satisfaction.
                    </p>
                    <p className="mobile_text">
                      Our App &amp; web Development service is a comprehensive solution
                      for all your needs, providing expert support every step of the
                      way.
                    </p>
                    <div className="mobile_2" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid" overflow="hidden;">
              <div className="row services_main" style={{ padding: 0, margin: 0 }}>
                <div
                  className="col-md-4  services_section "
                  data-aos="fade-up"
                  data-aos-duration={3000}
                >
                  <div className="services_section_secound first_section_services">
                    <div className="images_section">
                      <img src="assets/HomeIimages/Services/service-apple.webp" />
                    </div>
                    <div className="h4_text_section">
                      <h4>Ios App Development</h4>
                      <p>
                        Are you hoping to develop a cutting-edge iOS application that
                        will completely change how people use technology? You only need
                        to look at our staff of knowledgeable iOS app developers.<Link style={{ color: "blue" }} to="/about-us"> Mobile
                        App Development Company In Rohini.</Link> We have years of
                        experience and a passion for creativity, so we can create an
                        amazing look and faultless functionality for your app idea.
                      </p>
                    </div>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </div>
                </div>
                <div
                  className="col-md-4  services_section"
                  data-aos="fade-up"
                  data-aos-duration={3000}
                >
                  <div className="services_section_secound secound_section_services">
                    <div className="images_section" style={{ background: "#e1f1c1" }}>
                      <img src="assets/HomeIimages/Services/service-android.webp" />
                    </div>
                    <div className="h4_text_section">
                      <h4>Android App Development</h4>
                      <p>
                        Want to reach millions of Android users across the globe with
                        your app idea? To make it happen, our group of knowledgeable
                        Android app developers is here. <strong> Rohini's best App Development Company</strong> With a solid grasp of the Android platform and a
                        proven track record of launching apps that work, we are prepared
                        to make your idea a reality.
                      </p>
                    </div>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </div>
                </div>
                <div
                  className="col-md-4  services_section"
                  data-aos="fade-up"
                  data-aos-duration={3000}
                >
                  <div className="services_section_secound thard_section_services">
                    <div
                      className="images_section"
                      style={{ background: "rgb(253, 199, 199)" }}
                    >
                      <img src="assets/HomeIimages/Services/service-web.webp" />
                    </div>
                    <div className="h4_text_section">
                      <h4>Website Development</h4>
                      <p>
                        A strong online presence is crucial for organisations to prosper
                        in the digital age. Our team of knowledgeable web developers is
                        available to assist you in developing a polished and captivating
                        website that will draw visitors and encourage conversions.
                        <strong> Website Development Service In Delhi Ncr</strong>
                      </p>
                    </div>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-4 services_section"
                  data-aos="fade-up"
                  data-aos-duration={3000}
                >
                  <div className="services_section_secound four_section_services">
                    <div
                      className="images_section"
                      style={{ background: "rgb(170, 246, 246)" }}
                    >
                      <img src="assets/HomeIimages/Services/artifical_intelligence-1.webp" />
                    </div>
                    <div className="h4_text_section">
                      <h4>Custom App Development</h4>
                      <p>
                        Searching for a customised solution that fit your particular
                        company's requirements? <strong>Mobile App Development Company. </strong>
                         Our group specialises in creating
                        applications that are specially tailored to meet your needs.
                      </p>
                    </div>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-4 services_section"
                  data-aos="fade-up"
                  data-aos-duration={3000}
                >
                  <div className="services_section_secound five_section_services">
                    <div
                      className="images_section"
                      style={{ background: "rgb(193, 204, 255)" }}
                    >
                      <img
                        src="assets/HomeIimages/Services/service-hybrid.webp"
                        style={{ padding: 10 }}
                      />
                    </div>
                    <div className="h4_text_section">
                      <h4>Hybrid Mobile Applications</h4>
                      <p>
                        Searching for an affordable way to connect with Android and iOS
                        users? Creating mobile apps that blend the greatest features of
                        both worlds is our team's area of expertise. Digital Marketing
                        Service In Delhi Ncr . We can save you time and money by
                        implementing a single codebase to deliver a seamless user
                        experience across several platforms.
                      </p>
                    </div>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-sm-4 services_section"
                  data-aos="fade-up"
                  data-aos-duration={3000}
                >
                  <div className="services_section_secound six_section_services">
                    <div
                      className="images_section"
                      style={{ background: "rgb(188, 234, 255)" }}
                    >
                      <img src="assets/HomeIimages/Services/service-internet.webp" />
                    </div>
                    <div className="h4_text_section">
                      <h4>Internet Marketing</h4>
                      <p>
                        Internet marketing is crucial for firms to reach their target
                        audience and spur growth in the modern digital age. <Link style={{ color: "blue" }} to="/portfolio">Digital Marketing Service In Delhi Ncr</Link> To improve
                        your online presence, draw in more clients, and raise revenue,
                        our team of internet marketing specialists can assist you in
                        developing a thorough plan.
                      </p>
                    </div>
                    <i className="fa fa-long-arrow-right" aria-hidden="true" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End services ccoding */}
          {/* start porject section */}
          <section className="porject">
            <div
              className="text_section_services"
              style={{ paddingTop: 70, paddingBottom: 50 }}
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
            >
              <h2>Our Products</h2>
              <p>
                Our app helps you stay organised, productive, and connected on the go.
                From managing your schedule to tracking your fitness goals, our app
                offers a variety of tools to help you streamline your daily tasks.
              </p>
              <div className="mobile" />
            </div>
            <section
              className="Section_fiexd_one  align-items-center"
              id="development1"
            >
              <div className="container-fluid">
                <div className="fixed-mobile">
                  <div className="inner-phone d-flex align-items-center justify-content-center w-100">
                    <div className="home-project__phone">
                      <div className="phone__responsive">
                        <img src="assets/HomeIimages/Porject/Group 1000003597.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row main_porducts_section"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div
                    className="col-5 text_porject_section_logo"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <center>
                      <img
                        src="assets/HomeIimages/Porject/Reptile Tracko.png"
                        alt=""
                        className="logo"
                      />
                      <div className="playstore_img">
                        <img src="assets/HomeIimages/Porject/Group 1000003596.png" alt="" />
                        <img
                          src="assets/HomeIimages/Porject/App_Store_(iOS)-Badge-Alternative-Logo.wine 1.png"
                          alt=""
                        />
                      </div>
                    </center>
                  </div>
                  <div className="col-2 text_porject_section_logo" />
                  <div className="col-5 text_porject_section_logo">
                    <h1>Reptile Tracko</h1>
                    <p>
                      The Tracko app is designed to help businesses and organisations
                      track and monitor their assets, vehicles, and workforce in
                      real-time. It provides features such as GPS tracking, route
                      optimization, geofencing, and analytics to help improve
                      operational efficiency and productivity.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="Section_fiexd_tow  align-items-center"
              id="development1"
            >
              <div className="container-fluid">
                <div className="fixed-mobile">
                  <div className="inner-phone d-flex align-items-center justify-content-center w-100">
                    <div className="home-project__phone">
                      <div className="phone__responsive">
                        <img src="assets/HomeIimages/Porject/Group 1000003595 (2).png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row main_porducts_section"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div
                    className="col-5 text_porject_section_logo"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <center>
                      <img
                        src="assets/HomeIimages/Porject/Group (1).png"
                        alt=""
                        className="logo"
                      />
                      <div className="playstore_img">
                        <img src="assets/HomeIimages/Porject/Group 1000003596.png" alt="" />
                        <img
                          src="assets/HomeIimages/Porject/App_Store_(iOS)-Badge-Alternative-Logo.wine 1.png"
                          alt=""
                        />
                      </div>
                    </center>
                  </div>
                  <div className="col-2 text_porject_section_logo" />
                  <div className="col-5 text_porject_section_logo">
                    <h1>EMI Security Rapid</h1>
                    <p>
                      Carecone Technologies EMI Security Rapid App is a mobile
                      application developed by Carecone Technologies that focuses on
                      providing enhanced security features for individuals and
                      organisations. SEO Service In Avantika The app offers real-time
                      monitoring and tracking capabilities to ensure the safety and
                      security of users and their assets.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="Section_fiexd_three  align-items-center"
              id="development1"
            >
              <div className="container-fluid">
                <div className="fixed-mobile">
                  <div className="inner-phone d-flex align-items-center justify-content-center w-100">
                    <div className="home-project__phone">
                      <div className="phone__responsive">
                        <img src="assets/HomeIimages/Porject/Group 1000003599.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row main_porducts_section"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div className="col-5 text_porject_section_logo">
                    <center>
                      <img
                        src="assets/HomeIimages/Porject/Group 1000003601.png"
                        alt=""
                        className="logo"
                      />
                      <div className="playstore_img">
                        <img src="assets/HomeIimages/Porject/Group 1000003596.png" alt="" />
                        <img
                          src="assets/HomeIimages/Porject/App_Store_(iOS)-Badge-Alternative-Logo.wine 1.png"
                          alt=""
                        />
                      </div>
                    </center>
                  </div>
                  <div className="col-2 text_porject_section_logo" />
                  <div className="col-5 text_porject_section_logo">
                    <h1>GOwise</h1>
                    <p>
                      The Tech Stack is a combination of programming languages, tools,
                      and technologies that are used to develop a software application
                      or website. It includes front-end technologies for the user
                      interface, back-end technologies for server-side development, and
                      databases for storing and managing data.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </section>
          <section className="4 portfolio_mobile" data-aos="zoom-in-up">
            <div className="container-fluid">
              <div className="row portfolio_mobile_screen">
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="col-12 text_section_choose"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <div className="text_section_services_ind">
                      <h2>Our Products</h2>
                      <p>
                        Our app helps you stay organised, productive, and connected on
                        the go. From managing your schedule to tracking your fitness
                        goals, our app offers a variety of tools to help you streamline
                        your daily tasks
                      </p>
                      <div className="mobile" />
                    </div>
                  </div>
                </div>
                <div className="col-4 text_section">
                  <img src="assets/HomeIimages/working_images/work_title.webp" />
                </div>
                <div className="col-8 text_section">
                  <h5>
                    Connect with us
                    <br />
                    to Discuss a Project
                  </h5>
                </div>
              </div>
              {/* <div className="row portfolio_slide_screen_mobile">
                <div className="col-12" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="owl-carousel owl-theme"
                    style={{ background: "", padding: 0, margin: 0 }}
                  >
                    <div className="porducts_images_mobile">
                      <img src="assets/HomeIimages/working images/Gowisekart@0.5x" alt="" />
                      <div className="text_porducts_section_four">
                        <h1>Gowisekart</h1>
                        <p>
                          Their app and website, which we built, provide users with a
                          seamless shopping experience, making it easy to browse, order,
                          and track their&nbsp;purchases.
                        </p>
                      </div>
                    </div>
                    <div className="porducts_images_mobile">
                      <img src="assets/HomeIimages/working images/Security@0.5x.png" alt="" />
                      <div className="text_porducts_section_five">
                        <h1>Reptile EMI Security</h1>
                        <p style={{ fontSize: "13.4px" }}>
                          Reptile EMI Security is a feature that ensures all financial
                          transactions are secure and encrypted. This safeguard protects
                          sensitive information from unauthorized access and keeps
                          business data safe from potential threats
                        </p>
                      </div>
                    </div>
                    <div className="porducts_images_mobile">
                      <img src="assets/HomeIimages/working images/Tracko@0.33x.png" alt="" />
                      <div className="text_porducts_section_six">
                        <h1>Reptile Tracko</h1>
                        <p>
                          Reptile tracko is a time tracking and scheduling app that
                          allows employees to clock in and out, track their location,
                          and log their hours worked. It also offers GPS tracking for
                          remote employees
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row portfolio_slide_screen_mobile">
  <div className="col-12" style={{ padding: 0, margin: 0 }}>
  <SwiperSlider />

    {/* <div
      className="owl-carousel owl-theme"
      style={{ background: "", padding: 0, margin: 0 }}
    > */}
      {/* <div className="porducts_images_mobile">
        <img src="assets/HomeIimages/working images/Gowisekart@0.5x.png" alt="" />
        <div className="text_porducts_section_four">
          <h1>Gowisekart</h1>
          <p>
            Their app and website, which we built, provide users with a seamless
            shopping experience, making it easy to browse, order, and track
            their&nbsp;purchases.
          </p>
        </div>
      </div> */}
      {/* <div className="porducts_images_mobile">
        <img src="assets/HomeIimages/working images/Security@0.5x.png" alt="" />
        <div className="text_porducts_section_five">
          <h1>Reptile EMI Security</h1>
          <p style={{ fontSize: "13.4px" }}>
            Reptile EMI Security is a feature that ensures all financial
            transactions are secure and encrypted. This safeguard protects
            sensitive information from unauthorized access and keeps business
            data safe from potential threats
          </p>
        </div>
      </div> */}
      {/* <div className="porducts_images_mobile">
        <img src="assets/HomeIimages/working images/Tracko@0.33x.png" alt="" />
        <div className="text_porducts_section_six">
          <h1>Reptile Tracko</h1>
          <p>
            Reptile tracko is a time tracking and scheduling app that allows
            employees to clock in and out, track their location, and log their
            hours worked. It also offers GPS tracking for remote employees
          </p>
        </div>
      </div> */}
    {/* </div> */}
  </div>
</div>

            </div>
          </section>
          {/* end porject section */}
          {/* start counterdown timer */}
          <section className={5}>
            <div className="counterdown" data-aos="zoom-in">
              <div className="text_section_timer">
                <div className="text_timer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <h1 className="counter">400</h1>
                    <h1>+</h1>
                  </div>
                  <h5>Porject Delivered</h5>
                </div>
                <div className="text_timer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <h1 className="counter">1300</h1>
                    <h1>+</h1>
                  </div>
                  <h5>Happy Clients</h5>
                </div>
                <div className="text_timer">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <h1 className="counter">100</h1>
                    <h1>+</h1>
                  </div>
                  <h5>Award</h5>
                </div>
              </div>
            </div>
          </section>
          {/* end counterdown timer */}
          {/* start Expertise team */}
          <section className={3}>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row expertise_tem" style={{ padding: 0, margin: 0 }}>
                <div className="row" style={{ padding: 0, margin: 0 }}>
                  <div
                    className="col-12 text_section_choose"
                    style={{ padding: 0, margin: 0 }}
                    data-aos="fade-left"
                  >
                    <div className="text_section_services_ind">
                      <h2>Tech Stack</h2>
                      <p>
                        The Tech Stack is a combination of programming languages, tools,
                        and technologies that are used to develop a software application
                        or website. It includes front-end technologies for the user
                        interface, back-end technologies for server-side development,
                        and databases for storing and managing data
                      </p>
                      <div className="mobile" />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 Web_development" data-aos="zoom-out-up">
                  <div className="fortend">
                    <h2>Mobile Development</h2>
                    <h5>Native</h5>
                    <hr style={{ width: 140, marginLeft: 60 }} />
                  </div>
                  <div className="images_tec_section">
                    <div>
                      <img src="assets/HomeIimages/Porject/Swift.png" />
                      <h6>Swift</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/objC.png" alt="" />
                      <h6>objC</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/Kotlin.webp" alt="" />
                      <h6>Kotlin</h6>
                    </div>
                  </div>
                  <div className="fortend">
                    <h5 style={{ paddingTop: 40 }}>Hybrid</h5>
                    <hr style={{ width: 140, marginLeft: 60 }} />
                  </div>
                  <div className="images_tec_section">
                    <div>
                      <img src="assets/HomeIimages/Porject/Flutter.png" />
                      <h6>Flutter</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/React-Native.png" alt="" />
                      <h6>React Native</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 Web_development" data-aos="zoom-out">
                  <div className="fortend">
                    <h2>Web Development</h2>
                    <h5>Frontend</h5>
                    <hr style={{ width: 140, marginLeft: 60 }} />
                  </div>
                  <div className="images_tec_section">
                    <div>
                      <img src="assets/HomeIimages/Porject/angular.png" />
                      <h6>Angular</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/React-Native.png" alt="" />
                      <h6>React Native</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/Vue.js_.png" alt="" />
                      <h6>Vue JS</h6>
                    </div>
                  </div>
                  <div className="fortend">
                    <h5 style={{ paddingTop: 40 }}>Backend</h5>
                    <hr style={{ width: 140, marginLeft: 60 }} />
                  </div>
                  <div className="images_tec_section">
                    <div>
                      <img src="assets/HomeIimages/Porject/dotnet.svg" />
                      <h6>.Net</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/Java.png" alt="" />
                      <h6>Java</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/PHP.png" />
                      <h6>Php</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/Python.webp" alt="" />
                      <h6>Python</h6>
                    </div>
                    <div>
                      <img src="assets/HomeIimages/Porject/NodeJS.png" />
                      <h6>NodeJS</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* end Expertise team */}
          <section className={6}>
            <div className="container-fluid" style={{ overflow: "hidden" }}>
              <div className="row">
                <div
                  className="col-12 text_section_choose"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div className="text_section_services_ind">
                    <h2>Why Choose Us</h2>
                    <p>
                      Choosing Carecone Technologies for your software development needs
                      offers several advantages. <strong>Mobile App Development Company In Rohini.</strong>
                    </p>
                    <div className="mobile" />
                  </div>
                </div>
              </div>
              <div className="row" style={{ paddingTop: 50, paddingBottom: 70 }}>
                <div
                  className="col-sm-6 col-md-6 col-lg-6 images_section_choose"
                  data-aos="zoom-in"
                >
                  <img src="assets/HomeIimages/certificate/Why Choose us.png" alt="" />
                </div>
                <div
                  className="col-sm-6 col-md-6 col-lg-6 choose_text_section"
                  data-aos="fade-down-left"
                >
                  <div className="first_choose_section">
                    <div className="fa-icon">
                      <i className="fa fa-check" aria-hidden="true" />
                    </div>
                    <div>
                      <h4>Expertise</h4>
                      <h6>
                        {" "}
                        From AI integration to blockchain solutions, we combine our
                        expertise with innovation to create groundbreaking digital
                        experiences for our clients. Whether you need a simple app or a
                        complex e-commerce website, we have the skills and knowledge to
                        bring your ideas to life.
                      </h6>
                    </div>
                  </div>
                  <div className="first_choose_section">
                    <div className="fa-icon">
                      <i className="fa fa-check" aria-hidden="true" />
                    </div>
                    <div>
                      <h4>Customization</h4>
                      <h6>
                        we understand that every project is unique. That's why we pride
                        ourselves on offering customized solutions that are tailored to
                        meet the specific requirements of our clients. With a team of
                        experts in various programming languages and technologies, we
                        are dedicated to developing high-quality apps and websites that
                        exceed expectations.
                      </h6>
                    </div>
                  </div>
                  <div className="first_choose_section">
                    <div className="fa-icon">
                      <i className="fa fa-check" aria-hidden="true" />
                    </div>
                    <div>
                      <h4>Innovation</h4>
                      <h6>
                        Renowned for its expertise in creating customized solutions,
                        this innovative company is committed to delivering excellence
                        using a customer-centric approach.
                      </h6>
                    </div>
                  </div>
                  <div className="first_choose_section">
                    <div className="fa-icon">
                      <i className="fa fa-check" aria-hidden="true" />
                    </div>
                    <div>
                      <h4>Customer-centric approach</h4>
                      <h6>
                        We believe in building strong relationships with our clients,
                        understanding their needs and working closely with them
                        throughout the development process. Our goal is not just to
                        deliver a product, but to create a lasting partnership based on
                        trust and collaboration. When you choose Reptile India, you can
                        expect personalized attention and a dedicated team that is
                        committed to&nbsp;your&nbsp;success.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* end your Business coding */}
          {/* start wroking in company  */}
          <section className={7}>
            <div
              style={{ overflow: "hidden" }}
              className="services_section_industries"
            >
              <div className="text_section_services_ind"
               >
                <h2>Industries We Serve</h2>
                <p>
                  Carecone Technologies serves a wide range of industries with its
                  software development and technology solutions.
                </p>
                <div className="mobile" />
              </div>            
              <CardSlider />
            </div>
          </section>
          {/* end working in company */}
          {/* Certification section coding */}
          <div className="container-fluid"  style={{ marginTop: "-430px" }}>
            <div className="row">
              <div className="col-12 aword_section">
                <div className="aword_section_Certification">
                  <h2>Certification and award</h2>
                  <p>
                    Choosing Carecone Technologies for your software development needs
                    offers several advantages
                  </p>
                  <div className="mobile" />
                </div>
              </div>
              {/* large screen */}
              <div className="col-12 certification_images_section">
                <div className="row">
                  <div className="col-sm-2 certification_images">
                    <div className="images_section_certifite">
                      <img src="assets/HomeIimages/certificate/6.png" />
                      <h5>Microsoft</h5>
                      <p>Security, Speciality</p>
                    </div>
                  </div>
                  <div className="col-sm-2 certification_images">
                    <div className="images_section_certifite">
                      <img src="assets/HomeIimages/certificate/5.png" />
                      <h5>Microsoft</h5>
                      <p>Security, Speciality</p>
                    </div>
                  </div>
                  <div className="col-sm-2 certification_images">
                    <div className="images_section_certifite">
                      <img src="assets/HomeIimages/certificate/4.png" />
                      <h5>Microsoft</h5>
                      <p>Security, Speciality</p>
                    </div>
                  </div>
                  <div className="col-sm-2 certification_images">
                    <div className="images_section_certifite">
                      <img src="assets/HomeIimages/certificate/3.png" />
                      <h5>Microsoft</h5>
                      <p>Security, Speciality</p>
                    </div>
                  </div>
                  <div className="col-sm-2 certification_images">
                    <div className="images_section_certifite">
                      <img src="assets/HomeIimages/certificate/2.png" />
                      <h5>Microsoft</h5>
                      <p>Security, Speciality</p>
                    </div>
                  </div>
                  <div className="col-sm-2 certification_images">
                    <div className="images_section_certifite">
                      <img src="assets/HomeIimages/certificate/1.png" />
                      <h5>Microsoft</h5>
                      <p>Security, Speciality</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="mobile_section_hide"
                // data-aos="fade-up"
                // data-aos-anchor-placement="top-bottom"
              >
              <div className="item_certificates">
                <CardSlider2 />
                </div>
              </div>
            </div>
          </div>
          {/* end Certification section coding */}
          {/* work flow section start coding */}
          <section className="item_certificates_21" >
            <div className="container-fluid workFlow">
              <div className="row">
                <div className="text_section_services_work">
                  <div className="row">
                    <div
                      className="text_section_services"
                      style={{ paddingTop: 50, paddingBottom: 50 }}
                    >
                      <h2>OUR PROCESS</h2>
                      <p>
                        At Carecone Technologies, we follow a structured work process to
                        ensure the successful development and delivery of software
                        solutions.
                      </p>
                      <div className="mobile" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <img src="assets/HomeIimages/certificate/Landscape@0.5x.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid workfloe_mobile_screen">
              <div className="row" style={{ padding: 0, margin: 0 }}>
                <div className="col-12 aword_section" style={{ padding: 0, margin: 0 }}>
                  <div className="aword_section_Certification">
                    <h2>OUR PROCESS</h2>
                    <p>
                      At Carecone Technologies, we follow a structured work process to
                      ensure the successful development and delivery of software
                      solutions.
                    </p>
                    <div className="mobile" />
                  </div>
                </div>
              </div>
              <div style={{ padding: 0, margin: 0 }}>
                <img src="assets/HomeIimages/certificate/Portrait@0.33x.png" />
              </div>
            </div>
          </section>
          {/* end flow work section coding */}
          {/* start contact section */}
          
          <Footer />
        </>

      </>

    </Fragment>
  )
}
export default Index;