import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "animate.css";
import Navbar from "../Navbar";
import Footer from "../footer";
import "../index.css";
import { Link } from "react-router-dom";
function Career() {
  const [active, setactive] = useState(1);
  const [visable, setvisable] = useState(false);
  const [DataCarrer, SetDataCarrer] = useState({});
  const Handale = (id) => {
    setactive(id);
  };
  function close() {
    setvisable(false);
  }
  function SendData(data) {
    setvisable(true);
    SetDataCarrer(data);
  }
  console.log(DataCarrer);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const CreerData = [
    {
      Images: "/assets/GetQueryImages/pexels-cottonbro-4769490.jpg",
      Headeing: "Our research and innovation 1",
      Content: "We believe in the power of collective knowledge",
      Button: "Read More",
      ButtonGet:'Get Query',
      ContentAll:'    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
      Images: "/assets/GetQueryImages/pexels-cottonbro-4769490.jpg",
      Headeing: "Our research and innovation 2",
      Content: "We believe in the power of collective knowledge",
      ButtonGet:'Get Query',
      Button: "Read More",
      ContentAll:'    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
      Images: "/assets/GetQueryImages/pexels-cottonbro-4769490.jpg",
      Headeing: "Our research and innovation 3",
      Content: "We believe in the power of collective knowledge",
      ButtonGet:'Get Query',
      Button: "Read More",
      ContentAll:'    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
      Images: "/assets/GetQueryImages/pexels-cottonbro-4769490.jpg",
      Headeing: "Our research and innovation 4",
      Content: "We believe in the power of collective knowledge",
      ButtonGet:'Get Query',
      Button: "Read More",
      ContentAll:'    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut laboreLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
  ];
  
  return (
    <div>
      <Navbar />
      <div className="main_secrtion_carrer">
        <div className="container">
          <div className="row carrer_section">
            <div className="col-lg-6 text_carrer_section">
              <h4>Join Us</h4>
              <h2>Career</h2>
              <div
                style={{
                  background: "orangered",
                  height: "4px",
                  width: "120px",
                }}
              ></div>
              <p>
                We are looking for talents who can add cultural values at
                Hyperlink InfoSystem. If you think you are someone who has the
                same mission as ours, "to bring the best out of innovation" then
                it's time to welcome you onboard.
              </p>
             <div className="btn_carrer"> 
              <Link to='/contact-us'download><button>Contact</button></Link>
              <Link to='/contact-us'><button>Get Query</button></Link></div>
            </div>
            <div className="col-lg-6">
              <img src="/assets/GetQueryImages/Career.png" alt="images" />
            </div>
          </div>
        </div>
      </div>
      <div className="container slide_carrer_section">
        <div className="row">
          <div className="top">
            <h2>Process of Interview</h2>
            <p>
              Want to be the part of one of us? Here is the interview process we
              follow to evaluate your performance and welcome you onboard.
            </p>
          </div>
          <Carousel
            showDots={true}
            responsive={responsive}
            keyBoardControl={true}
            autoPlaySpeed={1000}
            transitionDuration={500}
            swipeable={false}
            draggable={false}
            ssr={true}
            AutoPlay
            mode={true}
          >
            {CreerData.map((items, index) => (
              <div
                className="slide_section_item"
                key={index}
                onClick={() => SendData(items)}
              >
                <div className="carrer_images">
                  <img src={items.Images} alt="Images" />
                </div>
                <div className="Text_over_layap_section_images">
                  <div className="Text_section_carrer">
                    <h2>{items.Headeing}</h2>
                    <p>{items.Content}</p>
                    <div className="button">
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                      {items.Button}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          {visable && (
            <div className="Detil_open_peages_carrer animate__animated animate__fadeIn">
              <div className="scroll_section">
                <div className="overlayp">
                  <div className="Images_section_carrer animate__animated animate__zoomIn">
                    <img
                     src={DataCarrer.Images}
                      alt="images"
                    />
                  </div>
                  <div className="text_carrere_section animate__animated animate__bounceInRight">
                    <div>
                      <h2>{DataCarrer.Headeing}</h2>
                      <i
                        class="fa fa-times"
                        aria-hidden="true"
                        onClick={close}
                      ></i>
                    </div>
                    <p>{DataCarrer.ContentAll}</p>
                    <div className="flows_section">
                    <Link to="/Contact"><button>{DataCarrer.ButtonGet}</button></Link>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container interview_section_main">
        <div className="row interview_section">
          <div>
            <h2>Process of Interview</h2>
            <p>
              Want to be the part of one of us? Here is the interview process we
              follow to evaluate your performance and welcome you onboard.
            </p>
          </div>
          <div
            className="col-lg-4 interview_section_qustion"
            style={{ padding: "0", margin: "0" }}
          >
            <div className="Images_carrer_section">
              <img
                src="/assets/GetQueryImages/interview-process-1.webp"
                alt="images"
              />
              <div className="text_carrer_images_section">
                <div className="text_over_section">
                  <h2 className="one">1.</h2>
                  <h5>Screening Interview</h5>
                  <div
                    style={{
                      background: "white",
                      height: "3px",
                      width: "130px",
                    }}
                  ></div>
                  <p className="paragraph">
                    This is usually the first HR round where the HR
                    representative screens candidates based on their resume,
                    basic qualifications, and sometimes asks preliminary
                    questions to assess their fit for the role and the company
                    culture.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 interview_section_qustion"
            style={{ padding: "0", margin: "0" }}
          >
            <div className="Images_carrer_section">
              <img
                src="/assets/GetQueryImages/interview-process-2.webp"
                alt="images"
              />
              <div className="text_carrer_images_section">
                <div className="text_over_section">
                  <h2 className="one">2.</h2>
                  <h5>Behavioral Interview</h5>
                  <div
                    style={{
                      background: "white",
                      height: "3px",
                      width: "130px",
                    }}
                  ></div>
                  <p className="paragraph">
                    In this type of HR round, candidates are asked questions
                    about how they have handled specific situations in the past.
                    The goal is to understand their behavior, decision-making
                    process, and problem-solving skills based on their previous
                    experiences.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 interview_section_qustion"
            style={{ padding: "0", margin: "0" }}
          >
            <div className="Images_carrer_section">
              <img
                src="/assets/GetQueryImages/interview-process-3.webp"
                alt="images"
              />
              <div className="text_carrer_images_section">
                <div className="text_over_section">
                  <h2 className="one">3.</h2>
                  <h5>Department Head Interview</h5>
                  <div
                    style={{
                      background: "white",
                      height: "3px",
                      width: "130px",
                    }}
                  ></div>
                  <p className="paragraph">
                    This round involves meeting with the HR manager or the head
                    of the department where the position resides. The focus here
                    is often on more detailed discussions about the candidate's
                    skills, experience, how they would fit into the team, and
                    sometimes more technical aspects related to the job role.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form_top_header_section">
        <div className="container form_career_section">
          <div className="row carrer_section">
            <div className="button_carrer_section">
              <h2>Stay ahead with our job opening notifications!</h2>
              <p>
              Apply first, succeed faster. Leap into your future career with our personalized job notifications.
              </p>
              <div>
              <button onClick={() => Handale(1)}>Fresher</button>
              <button onClick={() => Handale(2)}>Experience</button>
              </div>
            </div>
            <div
              className={
                active === 1
                  ? "col-12 fresher_section show_section"
                  : "Hide_section"
              }
              style={{ padding: "0", margin: "0" }}
            >
              <div className="form_carrer_section_top">
                <div className="personal_detail_section">
                  <div className="personal_detail">
                    <h6>1. Personal Details</h6>
                  </div>
                 
                  <div className="personal_detail_link_section">
                  <a href="/assets/GetQueryImages/pexels-cottonbro-4769490.jpg"download>
                    <h6>
                      <span>LIST OF DOCUMENT YOU NEED TO CARRY DURING YOUR</span>
                      DOCUMENT PROCESS{" "}
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </h6>
                    </a>
                  </div>
                
                </div>
                <div className="form_carrer">
                  <form>
                    <div className="Name_section">
                      <div>
                        <label>Full Name *</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Full Name *"
                        />
                      </div>
                      <div>
                        <label>personal Emaili Id *</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="personal Emaili Id *"
                        />
                      </div>
                    </div>
                    <div className="Name_sect">
                      <div>
                        <label>Contact Number*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Contact Number*"
                        />
                      </div>
                      <div>
                        <label>Alternate Number*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Alternate Number*"
                        />
                      </div>
                    </div>
                    <div className="Name_sect father_section">
                      <div>
                        <label>Father`s Name*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Father`s Name*"
                        />
                      </div>
                      <div>
                        <label>Father`s Contact Number?*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Father`s Contact Number?*"
                        />
                      </div>
                    </div>
                    <div className="Name_sect">
                      <div>
                        <label>Location*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Location*"
                        />
                      </div>
                      <button>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              className={
                active === 2
                  ? "col-12 Experiences_section show_section"
                  : "Hide_section"
              }
              style={{ padding: "0", margin: "0" }}
            >
              <div className="form_carrer_section_top">
                <div className="personal_detail_section">
                  <div
                    className="personal_detail"
                    style={{ background: "rgb(8, 175, 187)" }}
                  >
                    <h6>1.Details Experience</h6>
                  </div>
                  <div
                    className="personal_detail_link_section"
                    style={{ border: "1px solid rgb(8, 175, 187)" }}
                  >
                    <h6>
                     <span> LIST OF DOCUMENT YOU NEED TO CARRY DURING YOUR</span>
                      DOCUMENT PROCESS{" "}
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </h6>
                  </div>
                </div>
                <div className="form_carrer">
                  <form>
                    <div className="Name_section">
                      <div>
                        <label>Full Name *</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Full Name *"
                        />
                      </div>
                      <div>
                        <label>personal Emaili Id *</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="personal Emaili Id *"
                        />
                      </div>
                    </div>
                    <div className="Name_sect">
                      <div>
                        <label>Contact Number*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Contact Number*"
                        />
                      </div>
                      <div>
                        <label>Alternate Number*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Alternate Number*"
                        />
                      </div>
                    </div>
                    <div className="Name_sect">
                      <div>
                        <label>Companay Name*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Companay Name*"
                        />
                      </div>
                      <div>
                        <label>Years Of Experience*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Years Of Experience*"
                        />
                        {/* <select id="options" name="options">
               <option value="option1">Years 1</option>
               <option value="option2">Years 2</option>
               <option value="option3">Years 3</option>
               <option value="option4">Years 4</option>
               <option value="option5">Years 5</option>
               <option value="option6">Years 6</option>
               <option value="option7">Years 7</option>
               <option value="option8">Years 8</option>
               <option value="option9">Years 9</option>
               </select> */}
                      </div>
                    </div>
                    <div className="Name_sect">
                      <div>
                        <label>Location*</label>
                        <br></br>
                        <input
                          type="text"
                          name="name"
                          placeholder="Location*"
                        />
                      </div>
                      <button style={{ background: "rgb(8, 175, 187)" }}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Career;
