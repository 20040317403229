
import Navbar from "./Navbar";
import Footer from "./footer";
import './index.css'
const MobileApp = ()=>{
    return(
        <div>
            <Navbar />
            <div className="MobileAppSection">
          <div className="container">
            <div className="row">
                <div className="col-6 MobileApp_text_section"style={{padding:'0',margin:'0'}}>
                  <h2>Enterprise <br></br>Mobility Management Solution</h2>
                  <h6>Manage Enterprise Devices Seamlessly</h6>
                  <p>Enterprise mobility solution that allows IT admins and teams to manage devices by enforcing policies,Enterprise mobility solution that allows IT admins and teams to manage  remotely distributing apps and granting secure access to data.</p>
                  <div>
                 <button>Request a Demo</button>
                  </div>
                 
                </div>
                <div className="col-6 Images_section_mobileApp"style={{padding:'0',margin:'0'}}>
                  <div className="images_over_section">
                    
                    <div><i class="fa fa-star" aria-hidden="true"style={{color:'orangered'}}></i> Trustpilot</div>
                    <h6>50,125 Great Review</h6>
                  </div>
                  <img src="/assets/GetQueryImages/MobileImages/Banner-2 (1).png" alt="MobileApp"/>
                </div>
            </div>
          </div>
          </div>
          <div className="Feauture_mobile_app_section">
            <div className="container">
              <div className="text_features_section">
                <div className="Heading">
                
              <h2>Why Manage Enterprise Devices With Reptile</h2>
              <p>Enterprise Mobility Solution</p>
              </div>
              <div className="Psection">
              
              <p>CubiLock Enterprise Mobility Management (EMM) solution allows businesses to manage a wide range of Android devices. Manage, monitor, control, and secure all endpoints through a unified cloud-based interface</p>
            </div>
            </div>
            <div className="row">
              <div className="col-5 Images_section_features"style={{padding:'0',margin:'0'}}>
                <img src="/assets/GetQueryImages/MobileImages/mobile.png" alt="images"/>
              </div>
              <div className="col-7 Images_slide_section"style={{padding:'0',margin:'0'}}>
                <div className="Enrollment_section_maion">
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Bulk Device Enrollment</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/location.png" alt="images"/>
                  <h5>Geofencing/Location Tracking</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              <div className="Enrollment_section_maion">
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/online-payment.png" alt="images"/>
                  <h5>Application Management</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/monetary-policy.png" alt="images"/>
                  <h5>Device Policy Management</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              <div className="Enrollment_section_maion">
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Kiosk Mode</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Device Security</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              
              </div>
            </div>
            </div>
          
          </div>
          <div className="Award_section_top">
          <div className="container Award_images_section">
            <div className="row Award_images_section_mian">
            <div className="text_features_section">
                <div className="Heading">
                
              <h2>Solve Enterprise Mobility Challenges With CubiLock</h2>
            
              </div>
              <div className="Psection">
              
              <p>Gain complete visibility into performance and device policy management with CubiLock. Deploy business-critical apps to remote workforce, support and manage enterprise devices without worrying about device downtime</p>
            </div>
            </div>
              <div className="col-6 text_awarod_section"style={{padding:'0',margin:'0'}}>
                <div className="Text_award_section_secound">
                 <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Configure VPN/Wifi settings</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Communicate or send broadcast messages promptly</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Remotely wipe device data</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              <div className="Enrollment_section Text_award_section_secound">
                <div>
                  <img src="/assets/GetQueryImages/MobileImages/shield (1).png" alt="images"/>
                  <h5>Monitor Device in real-time</h5>
                  <p>Bulk Device Enrollment is a process used by organizations to efficiently enroll multiple devices into their device management system.</p>
                </div>
              </div>
              </div>
              </div>
              <div className="col-6 Images_Award_section_left"style={{padding:'0',margin:'0'}}>
                <img src="/assets/GetQueryImages/MobileImages/pagos2024.png" alt="images"/>
              </div>
            </div>
          </div>
          </div>
          
         <div className="container  EMM_Solution_pages_section_top">
         <div className="text_features_section">
                <div className="Heading"style={{width:'49%'}}>
                
              <h2>Enterprise Device Management
              Strategy That Fits Your Organization</h2>
             
              </div>
              <div className="Psection"style={{width:'40%'}}>
              <p>Manage, secure, and control endpoints from a single console, CubiLock's enterprise device
              management feature allows any businesses to manage their enterprise devices easily.</p>
            </div>
            </div>
          <div className="row EMM_Solution_pages_section_top_main">
          <img src="/assets/GetQueryImages/MobileImages/Asset 1.png" alt='images'/>
            <div className="Emm_solution_pages_item">
              <div className="Emm_solution_div_text">
                <div className="First_section_top_emm">
                
                  <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                  </div>
                </div>
              
                <div className="Secound_section_top_emm">
                
        
                <div className="Text_Emm_First_section">
                <h5>Reduce Cost</h5>
                <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                </div>
                <div className="Text_Emm_First_section">
                <h5>Reduce Cost</h5>
                <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                </div>
                <div className="Text_Emm_First_section">
                <h5>Reduce Cost</h5>
                <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                </div>
              </div>
              <div className="Thard_section_top_emm">
                 <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                  </div>
                  <div className="Text_Emm_First_section">
                  <h5>Reduce Cost</h5>
                  <p>Terminate cost of updating servers, reduce consumption of mobile data, allow applications that are essential for business.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>

         <div className="container Parcing_section">
          <div className="row">
            <div className="Priceing_section_text">
              <div className="Text_priceing">
                <h2>Our Pricing</h2>
                <p>Choose the right fit for your organization from our flexible and affordable range of pricing plans.</p>
              </div>
              <div className="Button_priceng_section">
              <h5>Binding period</h5>
              <div>
                <button>36 Months</button>
                <button>24 Months</button>
                <button>12 Months</button>
                <button>Months</button>
              </div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$2.50</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Standard Plans</h5>
               <p>Best suited for small companies’ shops & restaurants who needs couple of devices in KIOSK mode</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Sign Up</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$2.50</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Standard Plans</h5>
               <p>Best suited for small companies’ shops & restaurants who needs couple of devices in KIOSK mode</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Sign Up</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$2.50</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Standard Plans</h5>
               <p>Best suited for small companies’ shops & restaurants who needs couple of devices in KIOSK mode</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Sign Up</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
            <div className="col-3 pricingTable_main">
              <div className="pricingTable">
              <div className="pricingTable-header">
               <div className="price-value">
               <h3 style={{color:'orangered'}}>$2.50</h3>
               <h6>per device per month</h6>
               </div>
               <h5>Standard Plans</h5>
               <p>Best suited for small companies’ shops & restaurants who needs couple of devices in KIOSK mode</p>
               <div className="button_group">
               <button>Purchase Now</button>
               <button>Sign Up</button>
               </div>
              </div>
              <div className="title"></div>
              <div className="pricing-icon"></div>
              <div className="pricing-content"></div>
              <div className="price-value"></div>
              </div>
            </div>
          </div>
         </div>
            <Footer />
        </div>
    )
}
export default MobileApp;