import Navbar from "../Navbar";
import Footer from "../footer";
import "../index.css";
const Contact = () => {
  function scrooltoDown(){
    const element = document.getElementById('element-id'); 
    const y = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
  return (
    <div>
      <Navbar />
      <div className="top_header_section">
        <div className="container">
          <div className="row main_images_section">
            <div className="col-lg-6 Contact_images_section">
              <img src="/assets/GetQueryImages/img.4df64db8.svg" alt="images" />
            </div>
            <div className="col-lg-6 text_contact_section">
              <h2>Contact Us</h2>
              <div className="line_contact_section"></div>
              <p>
              Have questions or need more information about our services? Our team at Carecone Technologies Pvt. Ltd. is ready to assist you. Whether you're looking for IT solutions, need support, or want to discuss a project, we'd love to hear from you.
              </p>
              <div className="number_section">
                <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                <h6>+91 9311572747 </h6>
                <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                <h6>+91 8178334747 </h6>
              </div>
              <div className="fa_fa_section">
               <a href='https://www.instagram.com/reptileitservice'><i class="fa fa-instagram" aria-hidden="true"></i></a>
               <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Freptileindia">
                {" "}
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
                <a href="https://api.whatsapp.com/send?phone=+919311572747&text=Hello">
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </a>
                <a href="https://www.linkedin.com/company/reptileitservice/">
                <i className="fa fa-linkedin" aria-hidden="true" />
              </a>
              </div>
              <div className="btn_contact">
                <button onClick={()=>scrooltoDown()} >Get Query</button>
                <button onClick={()=>scrooltoDown()} >Contact</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_section_header" id="element-id">
        <div className="container Address_section">
          <div className="row">
            <div className="col-md-4 number_section_address">
              <div className="number_section_bottom">
                <div>
                  {" "}
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="number">
                  <h6>Mobile Number</h6>
                  <h6>+91 9311572747  <span className="number_hide"> +91 8178334747</span></h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 number_section_address">
              <div className="number_section_bottom">
                <div>
                  <i class="fa fa-paper-plane" aria-hidden="true"></i>{" "}
                </div>
                <div className="number">
                  <h6>Email</h6> <h6>nidhi@reptileindia.co.in</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 number_section_address">
              <div className="number_section_bottom">
                <div>
                  {" "}
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="number">
                  <h6>Website</h6>
                  <h6>www.reptileindia.co.in</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container form_section_main_contact">
          <div className="row">
            <div className="col-lg-6 form_section_text">
              <h2>Contact Us</h2>
              <div className="line_contact"></div>
              <form>
                <div className="form_section_text_main">
                  <div>
                    <label>Full Name</label>
                    <br></br>
                    <input type="text" name="name" placeholder="Name*" />
                  </div>
                  <div>
                    <label>Email Address</label>
                    <br></br>
                    <input
                      type="Email "
                      name="Email "
                      placeholder="Email Address*"
                    />
                  </div>
                </div>
                <div className="form_section_text_main">
                  <div>
                    <label>Subject</label>
                    <br></br>
                    <input
                      type="Email "
                      name="Email "
                      placeholder="Email Address*"
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <br></br>
                    <input
                      type="number"
                      name="number "
                      placeholder="Phone number*"
                    />
                  </div>
                </div>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                  <label>Message</label>
                  <br></br>
                  <textarea placeholder="Message" />
                </div>
                <button>Submit</button>
              </form>
            </div>
            <div
              className="col-lg-6 location_section"
              style={{ padding: "0", margin: "0" }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3499.639788430641!2d77.117685!3d28.
              70042!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d03c1266e5ae9%3A0x37cacfac2451d3ee!2sCarecone%20Technologies%20pvt
              .%20ltd.%20Mobile%20App%20Development%2C%20ios%20App%20Development%20Company%2C%20Software%20App%20Development%20
              company!5e0!3m2!1sen!2sin!4v1720521820550!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Contact;
